body {
  background: #000;
  color: #fff;
  margin: 0;
  font-family: proxima-soft, 'SF Pro Rounded', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2em;
  height: 100vh;
  height: -webkit-fill-available;
  /* overflow: hidden; */
  width: 100%;
  overscroll-behavior-y: none;
  scroll-behavior: smooth;
}

@media (min-width: 426px) {
  body {
    line-height: 1.5em;
  }
}

input,
button,
textarea {
  font-family: proxima-soft, 'SF Pro Rounded', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #fff;
  text-decoration: none;
}

hr {
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

* {
  --fa-primary-color: #e54b4d;
  --fa-secondary-color: #03a9f4;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
}
